import aboutMe from "./photos/stevensAbout.jpeg";
import folio1 from "./photos/folio1.png";
import folio2 from "./photos/folio2.png";
import clock from "./photos/clock.png";
import simon from "./photos/simon.jpeg";
import nike from "./photos/nike.png";
import adGen from "./photos/adGen.png";

const photos = {
  aboutMe,
  folio1,
  folio2,
  clock,
  simon,
  nike,
  adGen,
};

export default photos;
