import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated, config } from "react-spring";

import "./projectDivLink.css";

const calculateTilt = (x, y, pictureRef) => {
  const rect = pictureRef.current.getBoundingClientRect();
  const xRel = x - rect.left - rect.width / 2;
  const yRel = y - rect.top - rect.height / 2;

  const shadowX = -xRel / 30;
  const shadowY = -yRel / 30;

  const xTilt = -yRel / 250;
  const yTilt = xRel / 500;

  return {
    shadow: `${shadowX}px ${shadowY}px 20px rgba(50,50,93,0.25), ${
      shadowX / 2
    }px ${shadowY / 2}px 24px rgba(0,0,0,0.3)`,
    tilt: [xTilt, yTilt, 1.02],
  };
};

const transTilt = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

function ProjectDivLink({ image, title, techs, year }) {
  const [isProjectVisible, setisProjectVisible] = useState(false);
  const [tilt, setTilt] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));
  const [shadow, setShadow] = useState("");

  const pictureRef = useRef(null);

  useEffect(() => {
    const projectObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setisProjectVisible(true);
          projectObserver.unobserve(entry.target);
        }
      },
      { threshold: 0.2 }
    );

    if (pictureRef.current) {
      projectObserver.observe(pictureRef.current);
    }

    return () => {
      projectObserver.disconnect();
    };
  }, []);

  const handleMouseMove = ({ clientX: x, clientY: y }) => {
    const { shadow, tilt } = calculateTilt(x, y, pictureRef);
    setShadow(shadow);
    setTilt({ xys: tilt });
  };

  const handleMouseLeave = () => {
    setTilt({ xys: [0, 0, 1] });
    setShadow("none");
  };

  const projectSpringProps = useSpring({
    opacity: isProjectVisible ? 1 : 0,
    transform: isProjectVisible ? "translateY(0)" : "translateY(50px)",
    config: config.gentle,
  });

  return (
    <animated.div ref={pictureRef} style={{ ...projectSpringProps }}>
      <animated.div
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          transform: tilt.xys.to(transTilt),
          boxShadow: shadow,
          borderRadius: "0.9rem",
        }}
      >
        <div
          className="projectDivLink"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="projectDivLink__bottom__div">
            <div>
              <h5 className="projectDivLink__bottom__div__techs">{techs}</h5>
              <h1 className="projectDivLink__bottom__div__title">{title}</h1>
            </div>
            <div>
              <h2 className="projectDivLink__bottom__div__year">{year}</h2>
            </div>
          </div>
        </div>
      </animated.div>
    </animated.div>
  );
}

export default ProjectDivLink;
